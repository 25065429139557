// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__BillingMiscellaneousReportComponent__cancellationActivitiesTable{--table-border:1px solid var(--msa-grey-300);padding:var(--GW-SPACING-6)}.app__BillingMiscellaneousReportComponent__cancellationActivitiesTable th{background-color:var(--msa-grey-200);text-transform:unset}.app__BillingMiscellaneousReportComponent__cancellationActivitiesTable th:not(:last-child){border-right:var(--table-border)}.app__BillingMiscellaneousReportComponent__cancellationActivitiesTable tr{border-bottom:var(--table-border);border-top:var(--table-border)}.app__BillingMiscellaneousReportComponent__cancellationActivitiesTable tr:nth-child(2n) td{background-color:var(--msa-grey-100)}.app__BillingMiscellaneousReportComponent__cancellationActivitiesTable td{padding-bottom:0;padding-top:0}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/BillingMiscellaneousReportComponent/BillingMiscellaneousReportComponent.module.scss"],"names":[],"mappings":"AACA,uEACE,4CAAA,CACA,2BAAA,CAEA,0EACI,oCAAA,CACA,oBAAA,CACA,2FACI,gCAAA,CAGR,0EAEI,iCAAA,CADA,8BACA,CAGA,2FACI,oCAAA,CAGR,0EAEI,gBAAA,CADA,aACA","sourcesContent":["\n.cancellationActivitiesTable {\n  --table-border: 1px solid var(--msa-grey-300);\n  padding: var(--GW-SPACING-6);\n\n  th {\n      background-color: var(--msa-grey-200);\n      text-transform: unset;\n      &:not(:last-child) {\n          border-right: var(--table-border);\n      }\n  }\n  tr {\n      border-top: var(--table-border);\n      border-bottom: var(--table-border);\n  }\n  tr:nth-child(even) {\n      td {\n          background-color: var(--msa-grey-100);\n      }\n  }\n  td {\n      padding-top: 0px;\n      padding-bottom: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancellationActivitiesTable": "app__BillingMiscellaneousReportComponent__cancellationActivitiesTable"
};
export default ___CSS_LOADER_EXPORT___;

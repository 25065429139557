// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-dashboard-esign-margin:1.5rem 0 6.5rem}.app__EsignConfirmationPage__confirmationContainer{margin:var(--gw-dashboard-esign-margin);padding:var(--gw-dashboard-esign-margin)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/EsignConfirmationPage/EsignConfirmationPage.module.scss"],"names":[],"mappings":"AACA,MACI,2CAAA,CAEJ,mDACI,uCAAA,CACA,wCAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n:root {\n    --gw-dashboard-esign-margin: 1.5rem 0 6.5rem;\n}\n.confirmationContainer {\n    margin: var(--gw-dashboard-esign-margin);\n    padding: var(--gw-dashboard-esign-margin);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationContainer": "app__EsignConfirmationPage__confirmationContainer"
};
export default ___CSS_LOADER_EXPORT___;

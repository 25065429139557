// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-modal-title-padding-top:20px;--gw-modal-title-padding-bottom:25px;--gw-modal-width:500px;--gw-button-container-margin-top:20px;--gw-cancel-button-margin-right:10px}.app__LossMitigationComponent__modalWidth{width:var(--gw-modal-width)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/EHSCLossMitigationComponent/LossMitigationComponent.module.scss"],"names":[],"mappings":"AACA,MACE,iCAAA,CACA,oCAAA,CACA,sBAAA,CACA,qCAAA,CACA,oCAAA,CAGF,0CACE,2BAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n:root {\n  --gw-modal-title-padding-top: 20px;\n  --gw-modal-title-padding-bottom: 25px;\n  --gw-modal-width: 500px;\n  --gw-button-container-margin-top: 20px;\n  --gw-cancel-button-margin-right: 10px;\n}\n\n.modalWidth {\n  width: var(--gw-modal-width);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWidth": "app__LossMitigationComponent__modalWidth"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-policy-details-summary-section-top:1;--gw-policy-details-summary-section-bottom:2;--gw-policy-details-summary-section-margin-bottom:2rem;--gw-policy-columnHorizontal-width:300px;--gw-summary-details-icon-margin-right:0.5rem;--gw-summary-details-cost-value-margin-left:1rem}.app__Renewal__columnHorizontal{width:var(--gw-policy-columnHorizontal-width)}.app__Renewal__dropdownMenu{background-color:var(--msa-grey-50)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Renewal/Renewal.module.scss"],"names":[],"mappings":"AAEA,MACC,yCAAA,CACA,4CAAA,CACA,sDAAA,CACA,wCAAA,CACA,6CAAA,CACA,gDAAA,CAGD,gCACC,6CAAA,CAGD,4BACC,mCAAA","sourcesContent":["@import \"~@jutro/theme/assets/sass/helpers\";\n\n:root {\n\t--gw-policy-details-summary-section-top: 1;\n\t--gw-policy-details-summary-section-bottom: 2;\n\t--gw-policy-details-summary-section-margin-bottom: 2rem;\n\t--gw-policy-columnHorizontal-width: 300px;\n\t--gw-summary-details-icon-margin-right: 0.5rem;\n\t--gw-summary-details-cost-value-margin-left: 1rem;\n}\n\n.columnHorizontal {\n\twidth: var(--gw-policy-columnHorizontal-width);\n}\n\n.dropdownMenu {\n\tbackground-color: var(--msa-grey-50);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnHorizontal": "app__Renewal__columnHorizontal",
	"dropdownMenu": "app__Renewal__dropdownMenu"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__ProducerSummaryCardComponent__gridBox{background:var(--GW-BACKGROUND-COLOR);border-radius:var(--GW-SPACING-1);box-shadow:var(--GW-SHADOW-2);height:100%;padding:var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8)}.app__ProducerSummaryCardComponent__summaryDetails{display:flex;flex-direction:row}.app__ProducerSummaryCardComponent__summaryDetails div[class*=jut__FieldLabel__fieldLabelContainer]>label{width:-webkit-max-content;width:max-content}.app__ProducerSummaryCardComponent__summaryDetails div[class*=jut__FieldLabel__fieldLabelContainer]>label:after{content:\":\"}.app__ProducerSummaryCardComponent__summaryDetails div[class*=jut__FieldComponent__contentContainer]{padding-left:var(--GW-SPACING-1)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Components/ProducerSummaryCardComponent/ProducerSummaryCardComponent.module.scss"],"names":[],"mappings":"AAAA,4CACE,qCAAA,CACA,iCAAA,CACA,6BAAA,CAEA,WAAA,CADA,mEACA,CAGF,mDACE,YAAA,CACA,kBAAA,CACA,0GACE,yBAAA,CAAA,iBAAA,CAEF,gHACE,WAAA,CAEF,qGACE,gCAAA","sourcesContent":[".gridBox {\n  background: var(--GW-BACKGROUND-COLOR);\n  border-radius: var(--GW-SPACING-1);\n  box-shadow: var(--GW-SHADOW-2);\n  padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8);\n  height: 100%;\n}\n\n.summaryDetails {\n  display: flex;\n  flex-direction: row;\n  div[class*=\"jut__FieldLabel__fieldLabelContainer\"] > label {\n    width: max-content;\n  }\n  div[class*=\"jut__FieldLabel__fieldLabelContainer\"] > label::after {\n    content: \":\";\n  }\n  div[class*=\"jut__FieldComponent__contentContainer\"] {\n    padding-left: var(--GW-SPACING-1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridBox": "app__ProducerSummaryCardComponent__gridBox",
	"summaryDetails": "app__ProducerSummaryCardComponent__summaryDetails"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__PoliciesTableComponent__iconClass{align-self:center;font-size:var(--GW-SPACING-5);font-weight:bolder;height:var(--GW-SPACING-5)!important;line-height:var(--GW-SPACING-3);margin:0!important;padding:0!important;width:var(--GW-SPACING-5)!important}[class*=jut__Pagination__pagination] [class*=jut__Button__button]{max-width:-webkit-fit-content!important;max-width:fit-content!important;min-width:-webkit-fit-content!important;min-width:fit-content!important}[class*=jut__Pagination__pagination] [class*=jut__DropdownMenu__dropdownMenuPosition] [data-code*=All]{display:none}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Components/E1PPoliciesDashboardComponent/PoliciesTableComponent/PoliciesTableComponent.module.scss"],"names":[],"mappings":"AAAA,wCAGE,iBAAA,CAFA,6BAAA,CACA,kBAAA,CAEA,oCAAA,CAIA,+BAAA,CADA,kBAAA,CADA,mBAAA,CADA,mCAGA,CAIA,kEAEE,uCAAA,CAAA,+BAAA,CADA,uCAAA,CAAA,+BACA,CAIA,uGACE,YAAA","sourcesContent":[".iconClass {\n  font-size: var(--GW-SPACING-5);\n  font-weight: bolder;\n  align-self: center;\n  height: var(--GW-SPACING-5) !important;\n  width: var(--GW-SPACING-5) !important;\n  padding: 0px !important;\n  margin: 0px !important;\n  line-height: var(--GW-SPACING-3);\n}\n\n[class*='jut__Pagination__pagination'] {\n  [class*='jut__Button__button'] {\n    min-width: fit-content !important;\n    max-width: fit-content !important;\n  }\n  /* this will hide the ALL option from pageSize drop down*/\n  [class*='jut__DropdownMenu__dropdownMenuPosition'] {\n    [data-code*='All'] {\n      display: none;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconClass": "app__PoliciesTableComponent__iconClass"
};
export default ___CSS_LOADER_EXPORT___;

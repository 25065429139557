// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-analytics-img-min-height:550px;--gw-analytics-img-height:728px}.app__Analytics__analytics .app__Analytics__gwRemoveInputLabel{grid-template-columns:0fr auto}.app__Analytics__analytics .app__Analytics__gwRowLayout{margin-bottom:var(--GW-SPACING-6)}.app__Analytics__analytics .app__Analytics__gwProducerCodeDivider{border-left:solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH-THIN);height:calc(var(--GW-HEADER-HEIGHT-SM)*.6)}.app__Analytics__analytics .app__Analytics__gwGatewayReportFrame{height:var(--gw-analytics-img-height);min-height:var(--gw-analytics-img-min-height);width:100%}.app__Analytics__analytics .app__Analytics__gwAnalyticsPageTileContainer{padding-top:var(--GW-SPACING-3)}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-gateway-react/Analytics/Analytics.module.scss"],"names":[],"mappings":"AAEA,MACE,mCAAA,CACA,+BAAA,CAGA,+DACE,8BAAA,CAGF,wDACE,iCAAA,CAGF,kEAEE,oEAAA,CADA,0CACA,CAGF,iEACE,qCAAA,CAEA,6CAAA,CADA,UACA,CAGF,yEACE,+BAAA","sourcesContent":["\n\n:root {\n  --gw-analytics-img-min-height: 550px;\n  --gw-analytics-img-height: 728px;\n}\n.analytics {\n  .gwRemoveInputLabel {\n    grid-template-columns: 0fr auto;\n  }\n\n  .gwRowLayout {\n    margin-bottom: var(--GW-SPACING-6);\n  }\n\n  .gwProducerCodeDivider {\n    height: calc(0.6 * var(--GW-HEADER-HEIGHT-SM));\n    border-left: solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH-THIN);\n  }\n\n  .gwGatewayReportFrame {\n    height: var(--gw-analytics-img-height);\n    width: 100%;\n    min-height: var(--gw-analytics-img-min-height);\n  }\n\n  .gwAnalyticsPageTileContainer {\n    padding-top: var(--GW-SPACING-3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"analytics": "app__Analytics__analytics",
	"gwRemoveInputLabel": "app__Analytics__gwRemoveInputLabel",
	"gwRowLayout": "app__Analytics__gwRowLayout",
	"gwProducerCodeDivider": "app__Analytics__gwProducerCodeDivider",
	"gwGatewayReportFrame": "app__Analytics__gwGatewayReportFrame",
	"gwAnalyticsPageTileContainer": "app__Analytics__gwAnalyticsPageTileContainer"
};
export default ___CSS_LOADER_EXPORT___;

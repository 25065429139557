// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../platform/@jutro/cli-app/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--gw-modal-title-padding-top:20px;--gw-modal-title-padding-bottom:25px;--gw-button-container-margin-top:20px;--gw-cancel-button-margin-right:10px}.app__PaperlessDetailPopup__buttonContainer{display:flex;justify-content:flex-end;margin-top:var(--gw-button-container-margin-top)}.app__PaperlessDetailPopup__btnMargin{margin-right:var(--gw-cancel-button-margin-right)}.app__PaperlessDetailPopup__toggleField{align-content:right;margin:0;padding:0;width:150px}.app__PaperlessDetailPopup__commonStyle{min-width:200px;padding-right:10px;width:auto}.app__PaperlessDetailPopup__flexRow{display:flex;justify-content:flex-start;position:relative;width:auto}.app__PaperlessDetailPopup__smallPopUp{width:500px}", "",{"version":3,"sources":["webpack://./src/overrides/capabilities-react/e1p-capability-policyjob-react/components/PaperlessDetailPopup/PaperlessDetailPopup.module.scss"],"names":[],"mappings":"AAAA,MACE,iCAAA,CACA,oCAAA,CACA,qCAAA,CACA,oCAAA,CAGF,4CACE,YAAA,CACA,wBAAA,CACA,gDAAA,CAGF,sCACE,iDAAA,CAGF,wCAEE,mBAAA,CACA,QAAA,CACA,SAAA,CAHA,WAGA,CAGF,wCAEE,eAAA,CADA,kBAAA,CAEA,UAAA,CAGF,oCACE,YAAA,CAEA,0BAAA,CACA,iBAAA,CAFA,UAEA,CAGF,uCACE,WAAA","sourcesContent":[":root {\n  --gw-modal-title-padding-top: 20px;\n  --gw-modal-title-padding-bottom: 25px;\n  --gw-button-container-margin-top: 20px;\n  --gw-cancel-button-margin-right: 10px;\n}\n\n.buttonContainer {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: var(--gw-button-container-margin-top);\n}\n\n.btnMargin {\n  margin-right: var(--gw-cancel-button-margin-right);\n}\n\n.toggleField {\n  width: 150px;\n  align-content: right;\n  margin: 0px;\n  padding: 0px;\n}\n\n.commonStyle {\n  padding-right: 10px;\n  min-width: 200px;\n  width: auto;\n}\n\n.flexRow {\n  display: flex;\n  width: auto;\n  justify-content: flex-start;\n  position: relative;\n}\n\n.smallPopUp {\n  width: 500px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "app__PaperlessDetailPopup__buttonContainer",
	"btnMargin": "app__PaperlessDetailPopup__btnMargin",
	"toggleField": "app__PaperlessDetailPopup__toggleField",
	"commonStyle": "app__PaperlessDetailPopup__commonStyle",
	"flexRow": "app__PaperlessDetailPopup__flexRow",
	"smallPopUp": "app__PaperlessDetailPopup__smallPopUp"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Chevron } from '@jutro/components';
import { useTranslator } from '@jutro/locale';

import metadata from './E1PPropertyPageSectionHeaderComponent.metadata.json5';
import styles from './E1PPropertyPageSectionHeaderComponent.scss';

function E1PPropertyPageSectionHeaderComponent(props) {
    const SECTION_STATUS = {
        SUCCESS: 'success',
        FAILURE: 'failure',
        NONE: 'none'
    };
    const { isOpen, title, status } = props;
    const translator = useTranslator();
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        checkMark: {
            visible: status !== SECTION_STATUS.NONE,
            icon: status === SECTION_STATUS.SUCCESS
                ? 'mi-check-circle' : 'mi-error-outline',
            className: status === SECTION_STATUS.SUCCESS
                ? 'success-icon py-2' : 'error-icon py-2'
        },
        sectionHeader: {
            content: translator(title)
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <div
                className="accordionHeaderStyle"
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                />
                <Chevron className="chevronStyle py-1 mt-1" isOpen={isOpen} align="right" />
            </div>
    );
}

E1PPropertyPageSectionHeaderComponent.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.shape({}).isRequired,
    status: PropTypes.string
};
E1PPropertyPageSectionHeaderComponent.defaultProps = {
    isOpen: false,
    status: 'none'
};

export default E1PPropertyPageSectionHeaderComponent;
